// Utils
import { handleAnalyticsTrack } from "./Analytics";
import { postMessage } from "../services/NativePostMessage";

// Services
import i18n from "../i18n";

declare global {
  interface Window {
    appVersionCallback?: (version: string, build: string) => void;
  }
}

interface IButtonAction {
  type: "IN_APP_BROWSER" | "IN_APP" | "OPEN_WALLET" | "CONTACT" | "URL";
  destinationPageId?: string;
  url?: string;
  supportEmail?: string;
  supportEmailSubject?: string;
  supportPhoneNumber?: string;
  device_dependent_url?: boolean;
  android_url?: string;
  ios_url?: string;
  hideToolBar?: boolean;
  traverseWebHistoryOnBackPressed?: boolean;
}

export const handleOverrideClassName = (pageClassname) => pageClassname || "";

export const handleOverrideConfig = (themeConfig, pageConfig) => pageConfig || themeConfig;

export const limitItems = (items, limit) => (items?.length > limit ? items.slice(0, limit) : items);

export const handleJsBridgeForAppVersion = async () =>
  new Promise((resolve, reject) => {
    if (window.webkit) {
      // Native will call this function to pass the version and build number
      // let script = "appVersionCallback(\(version), \(build))"
      // webView.evaluateJavaScript(script)
      window.appVersionCallback = (version, build) => {
        resolve({ version, build });
      };

      postMessage("getAppVersion");
    } else if (window.Android) {
      try {
        const result = JSON.parse(postMessage("getAppVersion"));
        resolve(result);
      } catch {
        reject("Error in parsing the app version");
      }
    }
  });

export const handleRedirect = async (
  button_action: IButtonAction,
  history,
  buttonTitle,
  pageName,
  buttonDescription = ""
) => {
  const tempPagename = pageName || "homepage";

  if (button_action) {
    const url = handleDeviceDependentUrl(button_action);

    const webLinkParams = {
      browserType: button_action.type,
      url: url,
      title: buttonTitle,
      hideToolBar: button_action.hideToolBar ? button_action.hideToolBar : false,
      traverseWebHistoryOnBackPressed: button_action.traverseWebHistoryOnBackPressed
        ? button_action.traverseWebHistoryOnBackPressed
        : false,
    };

    if (
      button_action.type === "URL" ||
      button_action.type === "IN_APP_BROWSER" ||
      button_action.type === "IN_APP"
    ) {
      postMessage("openWebLink", webLinkParams);
    }

    // handling the separate behaviors of IN_APP
    if (button_action.type === "IN_APP") {
      if (button_action.destinationPageId) {
        history(`/${button_action.destinationPageId}`);
      }
    }

    if (button_action.type === "OPEN_WALLET") {
      if (window.webkit) {
        window.webkit.messageHandlers.ToApp.postMessage("WebPageCallbackOpenWallet");
      }

      if (window.Android) {
        window.Android.openWallet();
      }
    }

    if (button_action.type === "CONTACT") {
      const contactInfo = {
        supportEmail: button_action.supportEmail,
        supportEmailSubject: button_action.supportEmailSubject,
        supportPhoneNumber: button_action.supportPhoneNumber,
      };

      postMessage("showContactSheet", contactInfo);
    }
  }

  // Analytics
  // IOS

  let title = buttonTitle;

  // the following is a fix particular to St. Joe's Nav where a title on a nested page needs to be different than what is passed to analytics; TODO: fix for all scenarios
  if (buttonTitle.includes("News")) {
    title = buttonDescription === "" ? buttonTitle : buttonDescription;
  }

  // `${i18n.t(buttonTitle)} ${buttonTitle === "News" ? `: ${buttonDescription}` : ""}`
  if (window.webkit) {
    window.webkit.messageHandlers.ToApp.postMessage({
      type: "ANALYTICS",
      actions: "onClick_event",
      page: tempPagename,
      reason: `${i18n.t(title)}`,
      button_action,
    });
  } else if (window.Android) {
    // Android
    window.Android.registerAnalyticsEvent("onClick_event", tempPagename, `${i18n.t(title)}`);
  } else {
    // Web
    handleAnalyticsTrack(tempPagename, "onClick_event", `${i18n.t(title)}`);
  }
};

export const handleSliceContent = (numberOfItemsToShow, items) => {
  let itemReturn = items;
  if (numberOfItemsToShow && items?.length > numberOfItemsToShow) {
    itemReturn = items.splice(0, numberOfItemsToShow);
  }

  return itemReturn;
};

/**
 * This is a helper function to handle the device dependent url
 * @param button_action IButtonAction
 * @returns url - string
 */
export const handleDeviceDependentUrl = (button_action: IButtonAction) => {
  const url = button_action.device_dependent_url
    ? window.Android
      ? button_action.android_url
      : button_action.ios_url
    : button_action.url;

  return url;
};
