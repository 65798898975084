// Components
import RowItems from "./RowItem";

// Utils
import { handleOverrideClassName } from "../../utils/CommonFunctions";
import addTile from "./rowHelper";

// Interfaces
import { theme, contentRow, rowItem } from "../../ComponentInterface";

interface Props {
  theme: theme;
  pageContent: contentRow;
  pageName?: string;
}

const RegularRow = ({ pageContent, theme, pageName }: Props) => {
  const allItems = pageContent?.items ?? [];
  const itemsToDisplay = pageContent?.additional_tile ? addTile(pageContent, allItems) : allItems;

  if (!itemsToDisplay.length) return null;

  return (
    <div className={`row-container ${handleOverrideClassName(pageContent.rowContainerClassname)}`}>
      {itemsToDisplay.map((item, i) => (
        <RowItems key={i} rowItem={item} theme={theme} pageName={pageName} />
      ))}
    </div>
  );
};

export default RegularRow;
