import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect } from "react";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useApp } from "../../../../context/AppContext";
import { ShowTopBar, ShowBottomBar } from "../../../../services/ToggleNativeBar";
import Button from "../Components/Button";

const FilterOht = () => {
  useEffect(() => {
    ShowTopBar(true);
    ShowBottomBar(false);
  }, []);

  const [t] = useTranslation("translations");
  const history = useNavigate();

  const handleOnSaveClick = () => {
    // goes back
    history(-1);

    // UserService.setAttribute("ohts", items);
  };

  return (
    <div className="ls__page">
      <Helmet>
        <title>{t("LocationSelection.FilterOht.page_title")}</title>
      </Helmet>
      <div className="ls__page__inner">
        <div className="ls__pageheader_container">
          <p className="ls__pageheader">{t("LocationSelection.FilterOht.region")}</p>
        </div>
        <SelectRegion items={window.config.regions} />
      </div>
      <div className="ls__bottom_button">
        <Button isDisabled={false} onClickOverride={handleOnSaveClick} />
      </div>
    </div>
  );
};

interface SelectProps {
  items: Array<RegionsFilter>;
}

type RegionsFilter = {
  id: string;
  name: string;
  display_name: string;
};

const SelectRegion = ({ items = [] }: SelectProps) => {
  const [t] = useTranslation("translations");
  const { ohtFilter, setOhtFilter } = useApp();

  const handleChange = (event: SelectChangeEvent) => {
    setOhtFilter(event.target.value);
  };

  if (items?.length === 0)
    items = [
      {
        id: "1",
        name: "all_regions",
        display_name: "All Regions",
      },
    ];
  else
    items = [
      {
        id: "1",
        name: "all_regions",
        display_name: "All Regions",
      },
      ... items
    ];

  return (
    <Select
      value={ohtFilter}
      onChange={handleChange}
      displayEmpty
      inputProps={{ "aria-label": "Without label" }}
      renderValue={(select) => {
        if (select.length === 0) {
          return t("LocationSelection.FilterOht.default_value");
        }

        return items.find((item) => item.name === select).display_name;
      }}
    >
      {items.map((item) => (
          <MenuItem key={item.id} value={item.name}>
            {item.display_name}
          </MenuItem>
        ))}
    </Select>
  );
}

export default FilterOht;
