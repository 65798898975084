/* eslint-disable */

// Lib
import HaversineGeolocation from "haversine-geolocation";

// Utils
import { JSONQuery } from "../utils/JSONQuery";

// Interfaces
import { rowItem, selectedLocations } from "../ComponentInterface";

import UserService from "./UserService";

interface props {
  getDynamicContent(content_resource: string, url: boolean): any;
  filterDynamicContentBasedOnLocation(
    items: any,
    selectedLocations: Array<selectedLocations>
  ): any;
  filterDynamicContentFromUrlBasedonRule(
    items: any,
    selectionRule: string,
    numberOfItemsToShow: number
  ): any;
  handleFormatItemsBasedOnComponentToUse(
    items: Array<rowItem>,
    ItemComponentToUse: string,
    numberOfItemsToShow: any
  ): any;
  buildSelectionRule(selection_rule: string): string;
}

const DynamicContentServices: Partial<props> = {};

// Service to get the dynamic content from the config file

DynamicContentServices.getDynamicContent = function (content_resource, url) {
  // console.log(content_resource);
  return new Promise((resolve, reject) => {
    try {
      let result =
        window.config[content_resource as keyof typeof window.config] || [];

      if (url) {
        result = content_resource;
      }
      // console.log(window.config);
      resolve(result);
    } catch (error) {
      return reject("DynamicWidget.fetching_dynamic_content_error");
    }
  });
};

// Service to get Content Based on Location

DynamicContentServices.buildSelectionRule = function (selection_rule) {
  const rx = /(%%.*?%%)/g;

  const results = rx.exec(selection_rule);

  if (results === null) {
    return selection_rule;
  }
  for (let i = 0; i < results.length; i++) {
    const val = results[i.toString()].replace(/%%/g, "");

    const sel_args = val.split(".");
    const data = UserService.getAttribute(sel_args[0]);

    let replacement = "";

    if (!data || !data[sel_args[1]]) {
      replacement = "";
    } else {
      replacement = data[sel_args[1]];
    }

    selection_rule = selection_rule.replace(
      results[0],
      "'" + replacement + "'" 
    );
  }

  return selection_rule;
};

DynamicContentServices.filterDynamicContentBasedOnLocation = function (
  items,
  selectedLocations
) {
  return new Promise(async (resolve, reject) => {
    try {
      if (selectedLocations.length) {
        // 1. Loop through all the saved locations
        // Get the Distance for each item from the user location
        items = await getDistancefromLocationInKilometers(
          items,
          selectedLocations
        );

        // 2. Sort Items by Distance
        items.sort(
          (a: rowItem, b: rowItem) =>
            a.distanceFromLocation - b.distanceFromLocation
        );
      }

      resolve(items);
    } catch (error) {
      return reject("DynamicWidget.location_query_error");
    }
  });
};

const getDistancefromLocationInKilometers = function (
  items,
  selectedLocations: Array<selectedLocations>
) {
  return new Promise(async (resolve, reject) => {
    try {
      let filteredItems: Array<rowItem> = [];

      // Loop through Locations
      // Loop through each item from resource
      // Get the distance from location for each item
      // If under radius filter them

      await new Promise<void>(async (resolve, reject) => {
        selectedLocations.forEach((location: selectedLocations) => {
          if (location.position) {
            items.forEach(async (item: rowItem) => {
              // 1. Get the distance from the location
              let distance = 0;

              if (
                item.position &&
                item.position.latitude &&
                item.position.longitude
              ) {
                distance = await HaversineGeolocation.getDistanceBetween(
                  location.position,
                  item.position
                );
              }

              // 2. Check with radius
              if (distance <= location.distance) {
                item.distanceFromLocation = distance;
                item.locationID = location.id;
                filteredItems = [...filteredItems, item];
              }

              resolve();
            });
          }
        });
      });

      resolve(filteredItems);
    } catch (error) {
      return reject("DynamicWidget.location_distance_query_error");
    }
  });
};

// Service to Filter the results

DynamicContentServices.filterDynamicContentFromUrlBasedonRule = function (
  items,
  selectionRule,
  numberOfItemsToShow
) {
  return new Promise(async (resolve, reject) => {
    if (selectionRule && items.length) {
      try {
        // 1. Filter Data using JSON PAth Lib
        items = await JSONQuery(items, selectionRule);

        if (!items) return [];
      } catch (err) {
        return reject("DynamicWidget.selection_query_error");
      }
    }

    // 2. SLice Items based on config
    if (numberOfItemsToShow && items?.length > numberOfItemsToShow) {
      items = items.splice(0, numberOfItemsToShow);
    }

    resolve(items);
  });
};

// Service to build the item object

DynamicContentServices.handleFormatItemsBasedOnComponentToUse = function (
  items,
  ItemComponentToUse,
  ItemComponentSettings
) {
  const formattedItems = JSON.parse(JSON.stringify(items)) || [];

  return new Promise((resolve, reject) => {
    formattedItems?.length &&
      formattedItems.forEach((item: any) => {
        const staticCompList = [
          "A1",
          "PI2",
          "PI1",
          "IC2",
          "IC1",
          "FC4",
          "FC3",
          "FC2",
          "FC1",
          "Twitter",
        ];

        // Throw Error on no static component style chosen
        if (
          !ItemComponentToUse ||
          !staticCompList.includes(ItemComponentToUse)
        ) {
          return reject("DynamicWidget.no_component_style_selected_error");
        }

        item.itemType = ItemComponentToUse;
        const keepClassName = item.classname;

        // Add component Properties
        item = Object.assign(item, ItemComponentSettings);
        item.classname = keepClassName;

        // Build image and button action format

        item.button_action = {
          type: ItemComponentSettings.button_action?.type || "URL",
          url: item.url,
          button_settings:  ItemComponentSettings.button_action?.button_settings || {},
          hasActionableButton: ItemComponentSettings.button_action?.hasActionableButton || false,
          hideToolBar: ItemComponentSettings.button_action?.hideToolBar || false,
          traverseWebHistoryOnBackPressed: ItemComponentSettings.button_action?.traverseWebHistoryOnBackPressed || false,
        };

        // If Image Url is there
        if (item.image && !item.image.path) {
          item.image = {
            path: item.image,
            alt_text: item.title || item.description,
          };
        }

        // If Logo Url is there
        if (item.logo_url && !item.logo_url.path) {
          item.logo_image = {
            path: item.logo_url,
            alt_text: item.title || item.description,
          };
        }
      });

    resolve(formattedItems);
  });
};

export default DynamicContentServices;
