type NativeBridgeMessageTypes =
  | "WebPageCallbackOpenWallet"
  | "setBackButtonVisible"
  | "setBottomBarVisible"
  | "setTopBarVisible"
  | "openWebLink"
  | "showContactSheet"
  | "getAppVersion";

// eslint-disable-next-line import/no-mutable-exports
let postMessage: (type: NativeBridgeMessageTypes, value?: any) => any;

const isObject = (obj: unknown): boolean => obj !== null && typeof obj === "object";

const postMessageAndroid = (type, value) => {
  if (window.Android[type] != null) {
    if (isObject(value)) {
      return window.Android[type](...Object.values(value));
    } 
    
    if (value === undefined || value === null) {
      return window.Android[type]();
    }
    
    return window.Android[type](value);
  }
};

const postMessageIos = (type, value) => {
  window.webkit?.messageHandlers?.ToApp?.postMessage({
    type: type,
    value: value,
  });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const postMessageWeb = (type, value) => {
  /* Enable to debug in the browser
  console.log("Type - ", type);
  console.log("iOS values - ", {
    type: type,
    value: value,
  });

  if (isObject(value)) {
    console.log("Android values - ", ...Object.values(value));
  } else {
    console.log("Android values - ", value);
  }
  */
};

const initializeNativePostMessageService = () => {
  if (window.webkit) {
    postMessage = postMessageIos;
  } else if (window.Android) {
    postMessage = postMessageAndroid;
  } else {
    postMessage = postMessageWeb;
  }
};

export { postMessage, initializeNativePostMessageService };
